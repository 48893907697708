import React, { useEffect, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { GlobalHeader, PrimaryHeader, SecondaryHeader } from '@hagerty/global-header';
import { PrimaryNav } from '@hagerty/global-navigation';
import { useLocalStorage } from 'react-use';
import { useTreatments } from '@splitsoftware/splitio-react';

import { isAgent } from 'src/api/client';
import useBenefitsPageQueries from 'src/components/benefits/useBenefitsPageQueries';
import useSiteHeaderMenu from 'src/components/useSiteHeaderMenu';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import ensureTailingSlash from 'src/helpers/ensureTailingSlash';
import removePrefix from 'src/helpers/removePrefix';
import { useUserState } from './useUserState';
import { useTrackingContext } from 'src/components/TrackingContext';
import { GlobalSiteHeaderProps } from './GlobalSiteHeader.types';
import { Translate } from '../Translate';
import { isReady } from 'src/components/SplitContext/isReady';
import { Split, Treatment } from 'src/constants/Split';
import isBrowser from 'src/helpers/isBrowser';
import { useMfaLogin } from 'src/hooks/use-mfa-login';

export const GlobalSiteHeader = ({ location }: GlobalSiteHeaderProps) => {
  const menu = useSiteHeaderMenu(location);
  const [flags] = useLocalStorage('@FLAGS', {});
  const { ready, membership, hasLegacyMembership } = useBenefitsPageQueries();
  const { loading, isAuthenticated, status, firstName, lastName, sub, refresh, logout } = useUserState(location);
  const { isStfrmCustomer, hasStfCustomerStandalonePurchase } = useShelbyCustomer();
  const [activeSubnavItemIndex, setActiveSubnavItemIndex] = useState(-1);
  const [renderJoinTheClub, setRenderJoinTheClub] = useState(false);
  const login = useMfaLogin();

  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureAskHagerty, Split.FeatureGlobalNav]);
  const hasAskHagertyFeature = treatments[Split.FeatureAskHagerty].treatment === Treatment.On;
  const isFeatureGlobalNav = treatments[Split.FeatureGlobalNav].treatment === Treatment.On;

  const { trackInteraction } = useTrackingContext();

  const handleAskHagertyLinkClick = () => {
    trackInteraction('button', 'Ask Hagerty');
  };

  const minimal = useMemo(() => {
    const minimalHeadersPages: Record<string, boolean> = {
      ['/my-account/change-plan/']: true,
      ['/my-account/upgrade-membership/']: true,
      ['/my-account/downgrade-membership/']: true,
      ['/my-account/cancel-membership/']: true,
      ['/agents/unsigned-terms/']: true,
      ['/agents/existing-customer/']: true,
      ['/agents/commissions/']: true,
      ['/agents/commissions/terms-and-conditions']: true,
      ['/terms/']: true,
      ['/membership-issued/']: true,
      ['/agent-unauthorized/']: true,
    };
    const key = ensureTailingSlash(removePrefix(location.pathname));

    if (location.pathname.startsWith('/drivers-club/state-farm') || location.pathname.startsWith('/state-farm')) {
      return true;
    } else if (location.pathname.startsWith('/drivers-club/checkout') || location.pathname.startsWith('/checkout')) {
      return true;
    } else {
      return minimalHeadersPages[key];
    }
  }, [location.pathname]);

  const mainSite = (pathname): string =>
    (isAgent ? process.env.HAGERTY_AGENT_ROOT_URL : process.env.HAGERTY_ROOT_URL) + pathname;

  const user =
    isAuthenticated && !isAgent
      ? {
          firstName,
          lastName,
          avatar: undefined,
          sub,
        }
      : undefined;

  const userMenuOptions = [
    <a key="account-link" href={process.env.HDC_HAGERTY_ACCOUNT_PAGE}>
      Account
    </a>,
    <div
      key="log-out"
      onClick={() => {
        if (isBrowser) {
          // Remove cookie by expiring it
          document.cookie = 'accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
        return logout();
      }}
      data-cy="logout"
    >
      Log out
    </div>,
  ];

  if (flags['DEBUG']) {
    userMenuOptions.push(
      <div key="refresh" onClick={refresh}>
        Refresh
      </div>
    );
  }

  const navigationLinks = minimal
    ? undefined
    : menu.map((item) => ({
        href: item.link[status],
        title: item.label,
      }));

  const memebershipMenu = menu.find((item) => item.children?.length > 0)?.children;
  const subnavigationLinks = minimal
    ? undefined
    : memebershipMenu?.map((item) => {
        if (item.id === 'ask-hagerty' && membership && splitIsReady && hasAskHagertyFeature) {
          const href = `${process.env.HAGERTY_ROOT_URL}/drivers-club/member/ask-hagerty/`;
          return {
            link: (
              <a href={href} onClick={handleAskHagertyLinkClick}>
                {item.label}
              </a>
            ),
          };
        }

        if (item.id === 'hdc-days') {
          const href = `${process.env.HAGERTY_ROOT_URL}/drivers-club/hdc-days`;
          return {
            link: <a href={href}>{item.label}</a>,
          };
        }

        return {
          link: <Link to={item.link[status]}>{item.label}</Link>,
        };
      });

  useEffect(() => {
    const renderJoinTheClub =
      !loading &&
      ready &&
      !hasLegacyMembership &&
      !membership &&
      !minimal &&
      (!isStfrmCustomer || hasStfCustomerStandalonePurchase);

    setRenderJoinTheClub(renderJoinTheClub);
  }, [loading, ready, hasLegacyMembership, membership, isStfrmCustomer, hasStfCustomerStandalonePurchase]);

  const CHECKOUT_PATH = '/checkout/';

  const handleCTAClick = (event) => {
    event.preventDefault();
    navigate(CHECKOUT_PATH);
  };

  useEffect(() => {
    const activeHrefIndex = subnavigationLinks?.findIndex(
      (link) => link.link.props.to && ensureTailingSlash(link.link.props.to) === ensureTailingSlash(location.pathname)
    );
    setActiveSubnavItemIndex(activeHrefIndex);
  }, [location, subnavigationLinks]);

  if (isFeatureGlobalNav) {
    return (
      <>
        <PrimaryNav
          user={
            user
              ? {
                  family_name: user.firstName,
                  given_name: user.lastName,
                  sub: user.sub,
                }
              : undefined
          }
          dataApi="https://api.hagerty.com/gns/v1/header/usdirect"
          logoutAction={(e) => {
            e.preventDefault();
            if (isBrowser) {
              // Remove cookie by expiring it
              document.cookie = 'accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
            }
            logout();
          }}
          loginAction={(e) => {
            e.preventDefault();
            login(e);
          }}
        />

        <GlobalHeader>
          <SecondaryHeader
            activeNavItemIndex={activeSubnavItemIndex}
            menu={subnavigationLinks}
            ctaLabel={renderJoinTheClub ? 'Join the club' : undefined}
            ctaHref={renderJoinTheClub ? CHECKOUT_PATH : undefined}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore // ignore '(event: any) => void' is not assignable to type '() => void'
            ctaOnClick={handleCTAClick}
          />
        </GlobalHeader>
      </>
    );
  }

  return (
    <GlobalHeader>
      <PrimaryHeader
        activeNavItemIndex={2}
        branding={{
          alt: 'Hagerty Logo',
          href: mainSite(''),
          testid: 'nav-logo',
          type: 'default',
          wordmark: isAgent ? 'Drivers Club' : undefined,
        }}
        loginLink={
          isAgent ? (
            <Translate resourceKey="site-header.phone-number" />
          ) : (
            <a href="#" onClick={login} data-cy="login">
              Log in
            </a>
          )
        }
        navigationLinks={navigationLinks}
        user={user}
        userMenuOptions={userMenuOptions}
      />
      <SecondaryHeader
        activeNavItemIndex={activeSubnavItemIndex}
        menu={subnavigationLinks}
        ctaLabel={renderJoinTheClub ? 'Join the club' : undefined}
        ctaHref={renderJoinTheClub ? CHECKOUT_PATH : undefined}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // ignore '(event: any) => void' is not assignable to type '() => void'
        ctaOnClick={handleCTAClick}
      />
    </GlobalHeader>
  );
};
